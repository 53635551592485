// index.js -> bundle.js
var QRCode = require('qrcode');

document.addEventListener("turbolinks:load", function() {
  if(document.querySelector('.users')) {
    // QR Code generation
    const elements = document.querySelectorAll('.qr-code');
    console.log(elements);
    for (const element of elements) {
      url = element.dataset.url;
      QRCode.toCanvas(element, url)
    }

    // Filter functionality
    const filterInput = document.getElementById('filter');
    if (filterInput) {
      filterInput.addEventListener('keyup', function() {
        const value = this.value.toLowerCase();
        document.querySelectorAll('table tr').forEach(row => {
          row.style.display = row.textContent.toLowerCase().includes(value) ? '' : 'none';
        });
      });
    }
  }
});